<template>
    <div class="page-body wrapper-fixed">

        <div class="login-control d-flex">
            <button class="back-button skep-step" @click.prevent="continueAsCandidate" v-if="!isCompanyUser">
                <div class="back-button__icon">
                    <i class="eicon e-back"></i>
                </div>
                <div class="back-button__text">{{$t('Continue as a Candidate')}}</div>
            </button>
            <router-link :to="{name: 'dashboard'}" class="back-button skep-step" v-if="isCompanyUser">
                <div class="back-button__icon rotate-180">
                    <i class="eicon e-home"></i>
                </div>
                <div class="back-button__text">{{$t('Dashboard')}}</div>
            </router-link>
            <div class="ml-auto d-flex">
                <language-switcher></language-switcher>
                <router-link :to="{name: 'logout'}" class="back-button ml-4 text-capitalize">
                    <div class="back-button__icon">
                        <i class="eicon e-logout"></i>
                    </div>
                    <div class="back-button__text">{{ $t("Logout")}}</div>
                </router-link>
            </div>
        </div>
        <div class="col-md-6 login-thumbnail">
            <a href="#" class="site-logo">
                <site-logo-s-v-g/>
            </a>
            <img src="/img/Repeat Grid 3.png" alt="" class="login-page__background"/>
        </div>
        <div class="col-md-6 login-content">
            <div class="text-center">
                <h4 class="page-title">{{ $t("Easiest Recruitment Solution - Attract, Manage & Hire Right Talent.")}}</h4>
                <p class="page-description">{{ $t("Advanced Recruiting Solution With Everything You Need To Accelerate The Hiring Process.")}}</p>
            </div>
            <div class="login-box">
                <template v-if="successLoading">
                    <div class="alert alert-success d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="mr-2" style="shape-rendering: auto;" width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" r="24" stroke-width="4" stroke="#155724" stroke-dasharray="37.69911184307752 37.69911184307752" fill="none" stroke-linecap="round" transform="rotate(300.791 50 50)">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.01010101010101s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                            </circle>
                        </svg>
                        <p class="text-capitalize">{{$t('please wait')}}. . .</p>
                    </div>
                </template>
                <template v-else>
                    <div class="login-box__title">
                        <h2 class="text-capitalize">{{ $t("choose package")}}</h2>
                        <div class="sub_package_switcher">
                            <div class="sub_package_switch" @click="changePlanMode(subscriptionPlans.MONTHLY)" :class="{  'active' : subscriptionPlanSelected === subscriptionPlans.MONTHLY  }">{{ $t("Monthly") }}</div>
                            <div class="sub_package_switch" @click="changePlanMode(subscriptionPlans.YEARLY)" :class="{  'active' : subscriptionPlanSelected === subscriptionPlans.YEARLY  }">{{ $t("Yearly") }}</div>
                            <div class="sub_package_switch" @click="changePlanMode(subscriptionPlans.LIFETIME)" :class="{  'active' : subscriptionPlanSelected === subscriptionPlans.LIFETIME  }">{{ $t("Lifetime") }}</div>
                        </div>
                    </div>
                    <div class="package-box mt-4" v-if="packages.length">
                        <hooper :itemsToShow="3" :trimWhiteSpace="true">
                            <slide v-for="pack in activePackages" :key="pack.id">
                                <div class="subscription-package">
                                    <input type="radio" name="package" v-model="selected" :id="pack.slug" :value="pack.slug" @change="changePackage"/>
                                    <label class="package-card" :for="pack.slug">
                                        <h2 class="package-name" v-text="pack.name"></h2>
                                        <ul v-if="pack.attrs.length">
                                            <li v-for="attr in pack.attrs" v-html="attr.title"></li>
                                        </ul>
                                        <h3 class="package-price">
                                            <span class="discount" v-if="pack.has_discount">${{ pack.price }}</span>
                                            ${{ pack.discounted_price }}/{{ pack.plan }}
                                        </h3>
                                    </label>
                                </div>
                            </slide>
                            <ho-nav slot="hooper-addons"></ho-nav>
                        </hooper>
                    </div>
                    <template v-if="hasCouponOption">
                        <div class="form-group mt-4">
                            <label>{{$t('I have a Coupon Code!')}}</label>
                            <div class="d-flex">
                                <input type="text" class="form-control" v-model="couponCode" :placeholder="$t('Enter Coupon Code')"/>
                                <button class="button semi-button-info ml-2" :disabled="isLoading" @click.prevent="applyCouponDiscount">{{$t('apply')}}</button>
                            </div>
                            <error-message :message="errors.coupon"/>
                        </div>
                    </template>
                    <div class="selected-package-description" v-if="selectedPlan">
                        <ul>
                            <li>{{$t('Package Name')}} <span>{{selectedPlan.name}}</span></li>
                            <li>{{$t('Package Price')}}
                                <span v-if="selectedPlan.has_discount">${{selectedPlan.price}}</span>
                                <span v-else>${{selectedPlan.discounted_price}}</span>
                            </li>
                            <li v-if="selectedPlan.coupon_discount">{{$t('Coupon Discount')}} <template v-if="selectedPlan.discount_text">({{ selectedPlan.discount_text }})</template> <span>-${{selectedPlan.coupon_discount}}</span></li>
                            <li v-if="selectedPlan.has_discount && !selectedPlan.coupon_discount">{{$t('Discount')}} <span>-${{selectedPlan.discount}}</span></li>
                        </ul>
                        <div class="total-amount">{{$t('Total')}} <span>${{totalPrice()}}</span></div>
                    </div>
                    <div class="d-flex">
                        <submit-button type="info-button text-uppercase" :block="true" :loading="isLoading" :click="subscribeToFree" v-if="isFree">{{$t('Continue')}}</submit-button>
                        <submit-button type="info-button text-uppercase" :block="true" :loading="isLoading" :click="checkout" v-else>{{$t('confirm')}}</submit-button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
    import LanguageSwitcher from "../components/dropdown/LanguageSwitcher" ;
    import {mapGetters, mapActions, mapState} from 'vuex';
    import {SWITCH_TO_ACCOUNT, UPDATE_USER_DATA, USER_LOGGED_IN} from "../constants/action-type";
    import client from "../app/api/Client";
    import ErrorMessage from "../components/common/ErrorMessage";
    import { SUBSCRIPTION } from '../extra/constants';
    import { Hooper, Slide, Navigation as HoNav } from 'hooper';
    import 'hooper/dist/hooper.css';
    import SiteLogoSVG from "../components/SiteLogoSVG";
    import {COOKIES} from "../constants/enums";

    let stripe = Stripe(STRIPE_KEY);

    export default {
        components: {
            SiteLogoSVG,
            ErrorMessage,
            LanguageSwitcher,
            Hooper, Slide, HoNav
        },
        data() {
            return {
                couponCode: '',
                discount: null,
                errors: {
                    coupon: ''
                },
                packages: [],
                selected: '',
                selectedPlan: {},
                sessionId: '',
                isFree: false,
                isLoading: false,
                successLoading: false,
                hasCouponOption: true,
                subscriptionPlanSelected: SUBSCRIPTION.PACKAGE.PLAN.MONTHLY,
                subscriptionPlans: SUBSCRIPTION.PACKAGE.PLAN,
                isCompanyUser: false,
            }
        },
        computed: {
            ...mapState(['user']),
            activePackages() {
                let packs = this.packages.filter(e => e.plan_id === this.subscriptionPlanSelected);
                let freePackage = this.packages.find(e => e.slug === "free");
                if(freePackage){
                    _.remove(packs, {slug: 'free'});
                    packs.unshift(freePackage);
                }
                return packs;
            },
            promoter(){
                return _helper.getCookie(COOKIES.AFFILIATE) || null;
            },
            isSuccessMode(){
                return !!this.$route.query.success;
            },
            hasCompany(){
                return (this.user.companies.length > 0);
            }
        },
        methods: {
            ...mapActions([USER_LOGGED_IN, SWITCH_TO_ACCOUNT]),

            changePlanMode(plan) {
                this.subscriptionPlanSelected = plan;
            },

            async subscribeToFree() {
                this.isLoading = true;
                try {
                    let {data} = await client().post(`/subscribe`, {
                        plan: this.selected,
                        is_free: this.isFree,
                    });

                    this[USER_LOGGED_IN](data.data);
                    _helper.removeCookie(COOKIES.AFFILIATE);
                    this.$toast.success(this.$t(data.message));

                    if (data.data.companies.length > 0) {
                        let company = _.first(data.data.companies);
                        this[SWITCH_TO_ACCOUNT](company);
                        return this.$router.push({name: 'dashboard'});
                    }

                    await this.$router.push({name: 'company.create'});
                } catch (e) {
                    this.showErrorMessage(e);
                }
                this.isLoading = false;
            },
            showErrorMessage(e){
                if (typeof e === 'object') {
                    let {response: {status, data: {message}}} = e;
                    if (status === 400) {
                        this.$toast.error(this.$t(message));
                    }
                } else {
                }
            },
            async checkout() {
                this.isLoading = true;
                try {
                    if (!this.isFree) {
                        if (this.totalPrice() == 0 && (this.selectedPlan.discount == 0 || this.selectedPlan.plan_id === 3)) {
                            await this.createCheckoutSession();
                            return false;
                        } else {
                            await this.createCheckoutSession();
                        }
                    }
                    if (this.sessionId) {
                        localStorage.setItem('sessionId', this.sessionId);
                        let self = this;
                        stripe.redirectToCheckout({
                            sessionId: self.sessionId
                        }).then(function (result) {
                            if (result.error) {
                                self.$toast.error(result.error.message);
                            }
                        });
                    } else {
                        this.$toast.error(this.$t('Invalid session id'));
                    }
                } catch (e) {
                    this.showErrorMessage(e);
                }
                this.isLoading = false;
            },
            async applyCouponDiscount() {
                this.errors.coupon = '';
                // if (this.selectedPlan.discount > 0) {
                //     this.$toast.error(this.$t('Coupon not applicable.'));
                //     return false;
                // }

                if(_.isEmpty(this.couponCode)){
                    this.errors.coupon = this.$t('Please input coupon code.');
                    return 0;
                }

                this.isLoading = true;

                try {
                    let {data} = await client().get(`/coupon/${this.selected}/${this.couponCode}`);
                    this.couponCode = '';
                    if (data.data.discount > 0) {
                        this.discount = data.data;
                        this.selectedPlan.coupon_discount = this.discount.discount;
                        this.selectedPlan.discount_text = this.discount.discount_text;
                        this.$toast.success(this.$t('Coupon discount added.'));
                    }
                } catch (error) {
                    this.errors.coupon = error.response.data.message;
                    this.$toast.error(error.response.data.message);
                }
                this.isLoading = false
            },
            totalPrice() {
                if (this.selectedPlan.coupon_discount) {
                    return this.discount.discounted_price;
                } else {
                    return this.selectedPlan.discounted_price;
                }
            },
            async getPackages() {
                this.isLoading = true;
                try {
                    localStorage.setItem('sessionId', '');
                    let queryString = this.buildQueryString();
                    let {data} = await client().get(`/subscription/public-packages` + queryString);
                    this.packages = data.data;
                    let plan = this.$route.query.plan || null;
                    if (!_.isNull(plan)) {
                        let selectedPackage = this.packages.find(e => e.slug === plan);
                        if (!_.isEmpty(selectedPackage)) {
                            this.subscriptionPlanSelected = selectedPackage.plan_id;
                        }
                    }
                } catch (e) {
                }
                this.isLoading = false;
            },
            buildQueryString() {
                let queryString = '?';

                if (!_.isEmpty(this.$route.query.plan)) {
                    queryString += `plan=${this.$route.query.plan}`;
                }
                /* if (!_.isEmpty(this.$route.query.coupon)) {
                     queryString += `&coupon=${this.$route.query.coupon}`;
                 }*/

                return queryString;
            },
            async changePackage() {
                this.selectedPlan = _.find(this.packages, pack => pack.slug === this.selected);
                if (this.selectedPlan) {
                    this.isFree = (this.selectedPlan.discounted_price == 0 && this.selectedPlan.discount == 0);
                    this.hasCouponOption = !this.isFree;
                    this.discount = null;
                    if (!this.isSuccessMode && this.selectedPlan.slug !== this.$route.query.plan) {
                        await this.$router.push({name: 'subscribe', query: {plan: this.selectedPlan.slug}});
                    }
                }
            },
            async createCheckoutSession() {
                let {data: {data}} = await client().post(`/subscribe/session-create`, {
                    coupon: this.discount ? this.discount.code : '',
                    plan: this.selected,
                    promoter: this.promoter
                });

                if (data.isSubscribed) {
                    this[USER_LOGGED_IN](data.user);
                    localStorage.setItem('sessionId', '');
                    this.sessionId = '';
                    this.$toast.success(this.$t('Subscription successful.'));
                    if (this.hasCompany){
                        return await this.$router.push({name: 'dashboard'});
                    } else {
                        return await this.$router.push({name: 'company.create'});
                    }
                } else {
                    this.sessionId = data.session_id;
                }
            },
            async continueAsCandidate() {
                try {
                    let {data} = await client().get(`/continue/candidate`);
                    this[USER_LOGGED_IN](data.data);
                    _helper.removeCookie(COOKIES.AFFILIATE);
                    return await this.$router.push({name: 'dashboard'});
                } catch (e) {
                }
            },
            async checkSuccessfulPurchase() {
                if (this.$route.query.success && localStorage.getItem('sessionId')) {
                    this.successLoading = true;
                    try {
                        let {data} = await client().post('subscription/verify', {
                            plan: this.$route.query.success,
                            sessionId: localStorage.getItem('sessionId')
                        });
                        this[USER_LOGGED_IN](data.data);
                        localStorage.setItem('sessionId', '');
                        _helper.removeCookie(COOKIES.AFFILIATE);
                        this.$toast.success(this.$t('Subscription successful.'));
                        return this.$router.push({name: 'company.create', query: {redirectTo: 'dashboard'}});
                    } catch (e) {
                        this.$toast.error(this.$t(e.response.data.message));
                    }
                    this.successLoading = false;
                }
            },

            async checkForCoupon() {
                let coupon = this.$route.query.coupon;
                if (!_.isEmpty(coupon)) {
                    this.couponCode = coupon;
                    this.hasCouponOption = false;
                    await this.applyCouponDiscount();
                }
            },

            async redirectValidUsers() {
                let user = this.user;
                if (user.package_id && user.type == 'employer') {
                    this.isCompanyUser = true;
                    // if (!_.isEmpty(this.$route.query.plan)) {
                        // await this.$router.push({name: 'checkout', params: {slug: this.$route.query.plan}, query: {coupon: this.$route.query.coupon}});
                    // }
                }
            },

            async redirectToCompanyCreatePage() {
                if (this.user.package_id && this.user.companies.length === 0) {
                    return await this.$router.push({name: 'company.create'});
                }
            }
        },
        async created() {
            await this.redirectValidUsers();
            await this.checkSuccessfulPurchase();
            this.selected = this.$route.query.plan ?? 'free';
            await this.getPackages();
            await this.changePackage();
            await this.checkForCoupon();
            await this.redirectToCompanyCreatePage();
        }
    }
</script>
<style scoped>
    .login-box__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: initial;
    }

    .login-box__title h2 {
        font-size: 1.2rem;
    }

    .sub_package_switcher {
        display: flex;
    }

    .sub_package_switch {
        font-size: .9rem;
        font-weight: 400;
        padding: 5px 10px;
        border: 1px solid #587dfc;
        cursor: pointer;
    }

    .sub_package_switch:not(:first-child){
        margin-left: -1px;
    }

    .sub_package_switch:first-child {
        border-radius: 4px 0 0 4px;
    }

    .sub_package_switch:last-child {
        border-radius: 0 4px 4px 0;
    }

    .sub_package_switch.active {
        background: #587dfc;
        color: #fff;
    }

    .package-box {
        display: block;
    }

    .package-box .subscription-package {
        max-width: 100%;
        margin: 15px 5px;
    }

    .hooper {
        height: auto !important;
    }

    .rotate-180 {
        transform: rotate(0deg) !important;
    }
</style>


